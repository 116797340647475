import { Button, Col, Divider, Form, Image, Input, Row, Table, Tag, Typography, Select, message, Alert } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { HiOutlineSupport } from 'react-icons/hi'
import bckground from '../assets/images/background.png'
// import Select from 'react-select';
import { invoiceUsage, paymentForms, taxRegimes } from '../datasources/Invoice'
import { countryAlphaCodes } from '../datasources/Countries'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { VscLoading } from 'react-icons/vsc'
import { BiUser } from 'react-icons/bi'
import { BsCheckCircle } from 'react-icons/bs'
import { TbFileInvoice } from 'react-icons/tb'
import { axiosReq } from '../config/axios'
import { motion } from 'framer-motion'
import { formatter, getItemsAmounts } from '../functions/general'
import ModalDemo from '../modals/ModalDemo'
import PoweByGig from './PoweByGig'
import PrivacyBanner from './NoticeOfPrivacy'
import TryGigstackCard from './TryGigstackCard'
import { usePostHog } from 'posthog-js/react'
import { Info } from '@phosphor-icons/react'

export const LogoHeader = ({ supportEmail = '', alias = '', logo = '', livemode = true }) => {
    return (
        <Col xs={24}>
            <Row align="middle" justify="space-between">
                <Col xs={12}>
                    <Image src={logo} width={80} preview={false} alt="" />
                </Col>
                <Col xs={12}>
                    <Row justify="end">
                        <div className="d-flex flex-column" style={{ marginLeft: '10px', flex: 1 }}>
                            <Typography.Title level={5} style={{ margin: 0, textAlign: 'right' }}>
                                {alias}
                            </Typography.Title>
                            <Row align="middle" justify="end">
                                <HiOutlineSupport size={12} color={'#DDE1E7'} style={{ marginRight: '2px' }} />
                                <Typography.Text style={{ fontSize: '12px', color: '#DDE1E7' }}>
                                    {supportEmail}
                                </Typography.Text>
                            </Row>
                            {!livemode && (
                                <div>
                                    <Tag color="geekblue" style={{ marginLeft: '0px', fontSize: '10px' }}>
                                        Modo prueba
                                    </Tag>
                                </div>
                            )}
                        </div>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}

export const InvoiceForm = ({ receipt, onSuccess, version }) => {
    const posthog = usePostHog()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [allowedUsaged, setAllowedUsaged] = useState(invoiceUsage)
    const [creating, setCreating] = useState(false)
    const [state, setState] = useState(null)
    const [retries, setRetries] = useState(0)

    const {
        supportEmail,
        legal_name,
        country,
        zip,
        id,
        timestamp,
        validUntil,
        currency,
        pdf,
        livemode,
        client,
        items,
        paidIn,
        forcedPPD,
        payment_form: pform,
    } = receipt
    const payment_form = typeof pform === 'string' ? pform : pform?.value
    const [form] = Form.useForm()
    const [countryState, setCountryState] = useState(country)
    const { brand } = receipt.user
    const { logo, alias, primaryColor } = brand

    const onFinish = async (values) => {
        try {
            setError(null)
            var v = values
            setCreating('invoice')

            const id = new URLSearchParams(window.location.search).get('id')
            var body = {
                ...v,
                client: {
                    legal_name: v.legal_name.toUpperCase(),
                    rfc: v.tax_id,
                    email: v.email,
                    address: {
                        zip: v.zip,
                        country: v.country,
                    },
                    tax_system: v.tax_system,
                },
                use: v.use,

                customer: {
                    legal_name: v.legal_name.toUpperCase(),
                    rfc: v.tax_id,
                    email: v.email,
                    address: { zip: v.zip },
                    tax_system: v.tax_system,
                },
                rfc: v.tax_id,
                address: {
                    zip: v.zip,
                    country: v.country,
                },
                id: id,
                type: 'create',
            }

            if (v.payment_form) {
                if (v.payment_form === '99' && payment_form === '99' && !forcedPPD) {
                    setLoading(false)
                    return message.error('Por favor selecciona una forma de pago diferente a "Por definir')
                }
                body.payment_form = paymentForms.find(
                    (e) => e.value === v?.payment_form || e.label === v?.payment_form,
                )?.value
            }
            setTimeout(async () => {
                try {
                    const res = await axiosReq.post('resolveReceiptDataSeats', body)
                    posthog.identify(res.data?.clientId ?? res.data?.clientID ?? res.data?.client?.id, {
                        email: body?.client?.email,
                        name: body.client?.legal_name,
                    })
                    message.success(res?.response?.data?.message ?? 'Factura generada correctamente')

                    posthog.capture('receipt_succeeded', {
                        id: id,
                        version: version ?? 1,
                    })

                    // if (res?.data?.demo) {
                    //     setModalDemo(true);
                    // }
                    if (onSuccess) onSuccess(body)
                    setState(body)
                    setCreating(null)
                } catch (error) {
                    console.log(retries)
                    posthog.capture('receipt_failed', {
                        id: id,
                        // retries: retries + 1,
                        error: error?.response?.data?.message ?? 'Sin mensaje **',
                    })
                    setError(error?.response?.data?.message ?? 'Error al generar la factura')

                    message.error('Error al generar factura')
                    setCreating(null)
                }
            }, 5000)
            // // console.log(body);
            // // setCreating(null)
            // // return setLoading(false)
            // const res = await axiosReq.post('resolveReceiptDataSeats', body)
            // message.success(res?.response?.data?.message ?? 'Factura generada correctamente')
            // // updateReceipt({
            // //     status: 'completed'
            // // })

            // if (res?.data?.demo) {
            //     setModalDemo(true)
            // }
            // setState(body)
            // setCreating(null)
        } catch (error) {
            // console.log(error)
            setError(error?.response?.data?.message ?? 'Error al generar la factura')
            message.error('Error al generar factura')
            setCreating(null)
        }
    }

    useEffect(() => {
        var country = client?.country ?? client?.address?.country ?? 'Mexico'
        var isNacional = country === 'Mexico' || country === 'MEX' || country === 'México' || country === 'Mexico'
        var zip = client?.zip ?? client?.address?.zip ?? null
        var rfc = client?.rfc ?? client?.tax_id ?? null
        var tax_system = client?.tax_system ?? client?.taxSystem ?? (isNacional ? '601' : '616')
        var legalName = client?.legal_name ?? client?.name ?? null
        var use = (client?.use ?? isNacional) ? 'G03' : 'S01'

        if (receipt?.allowedUseOnSelfInvoice) {
            setAllowedUsaged((prev) => {
                return prev.filter((e) => receipt?.allowedUseOnSelfInvoice.includes(e.value))
            })
        }

        form.setFieldsValue({
            country: country,
            legal_name: legalName,
            zip: zip,
            tax_id: rfc,
            // payment_form: paymentForms.find((e) => e.value === payment_form),
            use,
            tax_system: tax_system,
            payment_form: paymentForms.find((e) => e.value === payment_form)?.label,
        })
    }, [])

    return (
        <Form
            layout="vertical"
            initialValues={{
                ...client,
                // country: validateCountryFirstlevel === null || validateCountrySecondLevel === null || validateCountryFirstlevel === "MEX" || validateCountrySecondLevel === "MEX" ? 'MEX' : validateCountryFirstlevel ?? client?.address?.country,
                // legal_name: client.legal_name ?? null,
                // zip: validateCountryFirstlevel === null || validateCountrySecondLevel === null || validateCountryFirstlevel === "MEX" || validateCountrySecondLevel === "MEX" ? client.zip ?? null : "0000 ",
                // tax_id: validateCountryFirstlevel === null || validateCountrySecondLevel === null || validateCountryFirstlevel === "MEX" || validateCountrySecondLevel === "MEX" ? client?.rfc ?? client?.tax_id ?? client?.taxId : 'XEXX010101000',
                // payment_form: paymentForms.find((e) => e.value === payment_form),
                // use: validateCountryFirstlevel === null || validateCountrySecondLevel === null || validateCountryFirstlevel === "MEX" || validateCountrySecondLevel === "MEX" ? client?.use ?? 'G03' : client?.use ?? 'S01',
                // tax_system: (validateCountryFirstlevel || validateCountrySecondLevel) === 'MEX' ? client?.tax_system : '616',
            }}
            onFinish={onFinish}
            form={form}
        >
            <Row className="w-100" gutter={[34, { xs: 5, md: 12 }]}>
                <Col xs={24} style={{ margin: '10px 0px' }}>
                    <Typography.Text className="neutral-1 h5-bold">Genera tu factura</Typography.Text>
                </Col>

                {/* <Col xs={24}>
                {!livemode && <Alert
                    style={{ marginBottom: '15px' }}
                    type="info"
                    showIcon={true}
                    icon={<Info size={15} />}
                    message={<Typography.Text style={{ fontSize: '12px', fontWeight: '500' }}>Este recibo es solo para fines de prueba y no tendrá validez fiscal</Typography.Text>}
                />}
            </Col> */}

                <Col xs={24}>
                    <Form.Item
                        label="Nombre o Razón Social"
                        style={{ marginBottom: '5px' }}
                        name="legal_name"
                        rules={[{ required: true, message: 'Por favor añade tu nombre legal' }]}
                    >
                        <Input placeholder="Nombre Legal" className="general_input" style={{ fontSize: '13px' }} />
                    </Form.Item>
                    <Typography.Text className="p-small-regular neutral-3">
                        {' '}
                        Conforme a la{' '}
                        <a
                            href="https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal"
                            target="_blank"
                            style={{ color: primaryColor ?? '#8666FF' }}
                            rel="noreferrer"
                        >
                            constancia de situación fiscal
                        </a>
                        : sin acentos, ni terminaciones.
                    </Typography.Text>
                </Col>
                <Col xs={24} md={24}>
                    <Form.Item
                        label={
                            countryState
                                ? countryState !== 'MEX' && countryState !== 'México' && countryState !== 'Mexico'
                                    ? 'Identificador Tributario'
                                    : 'RFC'
                                : 'RFC'
                        }
                        name="tax_id"
                        rules={[{ required: true, message: 'Por favor añade tu RFC' }]}
                    >
                        <Input
                            placeholder="RFC o Identificador tributario"
                            className="general_input"
                            size="medium"
                            style={{ fontSize: '13px', padding: '5px 10px' }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        label="Régimen Fiscal"
                        help={
                            countryState !== 'MEX' && countryState !== 'México' && countryState !== 'Mexico' ? (
                                <Typography.Text style={{ fontSize: '10px' }} type="secondary">
                                    Recomendado utilizar Sin obligaciones fiscales
                                </Typography.Text>
                            ) : (
                                ''
                            )
                        }
                        name="tax_system"
                        rules={[{ required: true, message: 'Por favor añade el régimen fiscal' }]}
                    >
                        <Select
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={taxRegimes.map((e) => {
                                return { value: e.value, label: `${e.label} (${e.value})` }
                            })}
                            className="general-select-input"
                            isLoading={false}
                            placeholder="Cuál es el régimen fiscal"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        label="Código Postal"
                        name="zip"
                        rules={[{ required: true, message: 'Por favor añade tu código postal' }]}
                    >
                        <Input
                            placeholder="Código Postal"
                            className="general_input"
                            size="medium"
                            style={{ fontSize: '13px', padding: '5px 10px' }}
                        />
                    </Form.Item>
                </Col>
                {((payment_form === '99' || payment_form === '01') && !forcedPPD) && (
                    <Col xs={24}>
                        <Form.Item
                            label="Forma de pago utilizada"
                            name="payment_form"
                            rules={[{ required: true, message: 'Por favor añade tu forma de pago' }]}
                        >
                            <Select
                                className="general-select-input"
                                options={
                                    //FROM paymentForms REMOVE THE OPTION WITH VALUE 99
                                    paymentForms
                                        .filter((e) => e.value !== '99')
                                        .map((e) => {
                                            return { value: e.value, label: `${e.label} (${e.value})` }
                                        })
                                }
                                isLoading={false}
                                placeholder="Forma de pago con la que se realizó el pago"
                                showSearch={true}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col xs={24} md={12}>
                    <Form.Item
                        label="Uso de la Factura"
                        help={
                            countryState !== 'MEX' && countryState !== 'México' && countryState !== 'Mexico' ? (
                                <Typography.Text style={{ fontSize: '10px' }} type="secondary">
                                    Recomendado utilizar Sin efectos fiscales
                                </Typography.Text>
                            ) : (
                                ''
                            )
                        }
                        name="use"
                        rules={[{ required: true, message: 'Por favor selecciona el uso' }]}
                    >
                        <Select
                            className="general-select-input"
                            options={allowedUsaged.length > 0 ? allowedUsaged : invoiceUsage}
                            isLoading={false}
                            placeholder="Uso del CFDI"
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="País" rules={[{ required: true }]} name="country">
                        <Select
                            className="general-select-input"
                            options={countryAlphaCodes.map((country) => {
                                return { label: country.name, value: country.code }
                            })}
                            onChange={(e) => {
                                if (!e) return
                                // console.log(e)
                                if (e.toUpperCase() !== 'MEX') {
                                    form.setFieldsValue({ tax_system: '616', use: 'S01' })
                                }

                                setCountryState(e)
                            }}
                            isLoading={false}
                            placeholder="País"
                            showSearch={true}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>
                </Col>

                <Col xs={24}>
                    <Form.Item
                        label="Correo electrónico"
                        name="email"
                        rules={[{ required: true, message: 'Por favor añade tu correo electrónico' }]}
                    >
                        <Input
                            size="medium"
                            placeholder="usuario@email.com"
                            type="email"
                            className="general_input"
                            style={{ fontSize: '13px', padding: '5px 10px' }}
                        />
                    </Form.Item>
                </Col>
                {/* <Col xs={24}>
                <Row align="middle">
                    <div className='d-flex flex-column flex-center' style={{ height: '30px', width: '30px', backgroundColor: '#F7F9FB', borderRadius: '50%' }}>
                        <TbFileInvoice style={{ fontSize: '15px' }} />
                    </div>
                    <Typography.Text type='secondary' style={{ marginLeft: '10px' }}>Información Fiscal</Typography.Text>
                </Row>
                <Divider />
            </Col> */}
                {/* {error && <Col xs={24}>
                    <Row justify="center">
                        <Typography.Text type="danger" style={{ fontSize: '12px', textAlign: 'center' }}>{error}</Typography.Text>
                    </Row>
                </Col>} */}
                {error && (
                    <Alert
                        description={<Typography.Text style={{ fontSize: '11px' }}>{error}</Typography.Text>}
                        message={
                            <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                Error al generar la factura
                            </Typography.Text>
                        }
                        type="error"
                        showIcon
                        style={{ width: '100%' }}
                        closable={true}
                    />
                )}

                {error !== 'El recibo ya no es válido' && (
                    <Col xs={24} style={{ marginTop: '20px' }}>
                        <Row justify="center">
                            <Button
                                className="btn-shadow"
                                block
                                size="large"
                                style={{
                                    backgroundColor: primaryColor ?? '#8666FF',
                                    border: 'none',
                                    borderRadius: '10px',
                                    color: 'white',
                                }}
                                loading={creating}
                                htmlType="submit"
                            >
                                {creating ? 'Creando factura' : 'Generar factura'}
                            </Button>
                        </Row>
                    </Col>
                )}
            </Row>
        </Form>
    )
}

function GenerateInvoice({ receipt, updateReceipt }) {
    const {
        supportEmail,
        legal_name,
        country,
        zip,
        id,
        timestamp,
        validUntil,
        currency,
        pdf,
        livemode,
        client,
        items,
        paidIn,
        payment_form: pform,
    } = receipt
    const payment_form = typeof pform === 'string' ? pform : pform?.value

    const itemsAmounts = getItemsAmounts({ items: items, useDiscount: true })
    const { brand } = receipt.user
    const { logo, alias, primaryColor } = brand
    const [modalDemo, setModalDemo] = useState(false)

    const [state, setState] = useState(null)
    const [allowedUsaged, setAllowedUsaged] = useState(invoiceUsage)

    const [downloading, setDownloading] = useState(false)
    const [loading, setLoading] = useState(false)

    const [form] = Form.useForm()
    const [fileLinks, setFileLinks] = useState({
        pdfLink: null,
        xmlLink: null,
    })

    useEffect(() => {
        var country = client?.country ?? client?.address?.country ?? 'Mexico'
        var isNacional = country === 'Mexico' || country === 'MEX' || country === 'México' || country === 'Mexico'
        var zip = client?.zip ?? client?.address?.zip ?? null
        var rfc = client?.rfc ?? client?.tax_id ?? null
        var tax_system = client?.tax_system ?? client?.taxSystem ?? (isNacional ? '601' : '616')
        var legalName = client?.legal_name ?? client?.name ?? null
        var use = (client?.use ?? isNacional) ? 'G03' : 'S01'

        if (receipt?.allowedUseOnSelfInvoice) {
            setAllowedUsaged((prev) => {
                return prev.filter((e) => receipt?.allowedUseOnSelfInvoice.includes(e.value))
            })
        }

        form.setFieldsValue({
            country: country,
            legal_name: legalName,
            zip: zip,
            tax_id: rfc,
            // payment_form: paymentForms.find((e) => e.value === payment_form),
            use,
            tax_system: tax_system,
            payment_form: paymentForms.find((e) => e.value === payment_form)?.label,
        })
    }, [])

    const handleDownloadInvoice = async () => {
        try {
            const params = new URLSearchParams(window.location.search)
            const id = params.get('id')
            if (loading) return
            setLoading(true)
            const data = await axiosReq.post('resolveReceiptDataSeats', {
                id,
                type: 'retrieve',
                rfc: state.rfc?.toUpperCase(),
            })

            setFileLinks({
                pdfLink: data.data.url[0],
                xmlLink: data.data.urlxml[0],
            })
            setLoading(false)
        } catch (error) {
            // console.log(error)
            setLoading(false)
            message.error(error?.response?.data?.message ?? 'Ocurrió un error')
        }
    }

    const handleDownloadPdf = () => {
        const { pdfLink } = fileLinks
        if (pdfLink) {
            const a = document.createElement('a')
            a.target = '_blank'
            a.href = pdfLink
            a.download = `${id}-${state.rfc}.pdf`
            a.click()
            a.remove()
        }
    }

    const handleDownloadXml = () => {
        const { xmlLink } = fileLinks
        if (xmlLink) {
            const a = document.createElement('a')
            a.target = '_blank'
            a.href = xmlLink
            a.download = `${id}-${state.rfc}.xml`
            a.click()
            a.remove()
        }
    }

    const LegalData = () => {
        return (
            <Col xs={12} md={12} lg={10}>
                <div className="d-flex flex-column">
                    <Typography.Text type="secondary" style={{ fontSize: '11px', textAlign: 'right' }}>
                        Emisor
                    </Typography.Text>
                    <Typography.Text type="secondary" style={{ fontSize: '11px', textAlign: 'right' }}>
                        {legal_name}
                    </Typography.Text>
                    <Typography.Text type="secondary" style={{ fontSize: '11px', textAlign: 'right' }}>
                        {country ?? 'MEX'} - {zip ?? ''}
                    </Typography.Text>
                </div>
            </Col>
        )
    }

    const DownloadingInvoice = () => {
        return (
            <Col>
                <Divider />
                <Row justify="center" aling="middle">
                    <VscLoading
                        style={{
                            animation: 'spin 1s infinite linear',
                            color: primaryColor,
                            fontSize: '30px',
                        }}
                    />
                </Row>
                <Row justify="center" aling="middle" style={{ marginTop: '10px' }}>
                    <Typography.Text type="secondary" style={{ fontSize: '15px' }}>
                        Descargando factura
                    </Typography.Text>
                </Row>
            </Col>
        )
    }

    const ReceiptData = () => {
        return (
            <div
                className="d-flex flex-column"
                style={{
                    padding: '10px',
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    marginBottom: '20px',
                    // boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.04)',
                    backgroundImage: `url(${bckground})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <Typography.Text>Recibo de venta </Typography.Text>
                <Row justify="space-between" style={{ marginBottom: '10px', marginTop: '5px' }}>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>ID</Typography.Text>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>{id}</Typography.Text>
                </Row>
                <Row justify="space-between" style={{ marginBottom: '10px' }}>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>Fecha de emisión</Typography.Text>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                        {moment(timestamp).format('DD/MM/YYYY HH:mm')}
                    </Typography.Text>
                </Row>
                <Row justify="space-between" style={{ marginBottom: '10px' }}>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        Válido para facturación
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                        {moment(validUntil).format('DD/MM/YYYY HH:mm')}
                    </Typography.Text>
                </Row>
            </div>
        )
    }

    const InvoicesSuccessCard = () => {
        return (
            <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
                <Row style={{ width: '100%' }} gutter={{ xs: 5, md: 10, lg: 12 }} justify="center">
                    <Col xs={24}>
                        <Divider />
                        <Row justify="center">
                            <div
                                className="d-flex flex-column flex-center"
                                style={{
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: '50%',
                                    backgroundColor: '#F7F9FB',
                                }}
                            >
                                <BsCheckCircle style={{ fontSize: '18px', color: primaryColor }} />
                            </div>
                        </Row>
                        <Row justify="center" style={{ marginTop: '15px' }}>
                            <Typography.Title level={4} style={{ color: '#333333', margin: 0 }}>
                                Factura creada correctamente
                            </Typography.Title>
                        </Row>
                        <Row justify="center">
                            <Typography.Text type="secondary">
                                Hemos enviado la factura a tu correo electrónico
                            </Typography.Text>
                        </Row>
                        {fileLinks.pdfLink === null && fileLinks.xmlLink === null && (
                            <div
                                className="d-flex flex-row flex-center"
                                style={{
                                    width: '100%',
                                    padding: '15px',
                                    borderRadius: '10px',
                                    backgroundColor: primaryColor,
                                    cursor: downloading ? 'wait' : 'pointer',
                                    marginTop: '15px',
                                }}
                                onClick={() => {
                                    handleDownloadInvoice()
                                }}
                            >
                                <Row align="middle">
                                    {downloading ? (
                                        <VscLoading
                                            style={{
                                                animation: 'spin 1s infinite linear',
                                                marginRight: '14px',
                                                color: primaryColor,
                                            }}
                                        />
                                    ) : (
                                        <AiOutlineCloudDownload
                                            style={{ marginRight: '14px', color: 'white', fontSize: '18px' }}
                                        />
                                    )}
                                    <Typography.Text style={{ color: 'white' }}>Descargar factura</Typography.Text>
                                </Row>
                            </div>
                        )}
                        {fileLinks.pdfLink !== null && fileLinks.xmlLink !== null && (
                            <Row justify="center" style={{ marginTop: '15px' }}>
                                <DownloadInvoices />
                            </Row>
                        )}
                    </Col>
                </Row>
            </motion.div>
        )
    }

    const DownloadInvoices = () => {
        return (
            <Row justify="center">
                <Col xs={24}>
                    <Row justify="center">
                        <div
                            className="d-flex flex-row flex-center"
                            style={{
                                width: '100%',
                                padding: '15px',
                                borderRadius: '10px',
                                backgroundColor: primaryColor,
                                cursor: downloading ? 'wait' : 'pointer',
                                marginTop: '15px',
                            }}
                            onClick={() => {
                                handleDownloadPdf()
                            }}
                        >
                            <Row align="middle">
                                <Typography.Text style={{ color: 'white' }}>Descargar factura PDF</Typography.Text>
                            </Row>
                        </div>
                        <div
                            className="d-flex flex-row flex-center"
                            style={{
                                width: '100%',
                                padding: '15px',
                                borderRadius: '10px',
                                backgroundColor: primaryColor,
                                cursor: downloading ? 'wait' : 'pointer',
                                marginTop: '15px',
                            }}
                            onClick={() => {
                                handleDownloadXml()
                            }}
                        >
                            <Row align="middle">
                                <Typography.Text style={{ color: 'white' }}>Descargar factura XML</Typography.Text>
                            </Row>
                        </div>
                    </Row>
                </Col>
            </Row>
        )
    }

    const ReceiptAmount = () => {
        return (
            <div
                className="d-flex flex-column"
                style={{ width: '100%', backgroundColor: 'white', padding: '10px', borderRadius: '10px' }}
            >
                <Header />

                <Row justify="space-between">
                    <Typography.Text style={{ marginTop: '15px' }} type="secondary">
                        Total pagado{' '}
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: '30px', fontWeight: 900, marginLeft: '10px' }}>
                        {paidIn === 'bank' && itemsAmounts.feeAdded > 0
                            ? itemsAmounts.totalWithoutFeeString
                            : itemsAmounts.totalString}{' '}
                        <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                            {(currency ?? 'MXN').toUpperCase()}
                        </span>{' '}
                    </Typography.Text>
                </Row>
                <Typography.Text style={{ marginTop: '15px' }} type="secondary">
                    Productos / Servicios{' '}
                </Typography.Text>
                <Table
                    dataSource={items}
                    showHeader={false}
                    pagination={{
                        hideOnSinglePage: true,
                    }}
                    columns={[
                        {
                            title: 'Concepto',
                            dataIndex: 'name',
                            key: 'name',
                            render: (text, record) => {
                                return <Typography.Text style={{ fontSize: '12px' }}>{text}</Typography.Text>
                            },
                        },
                        {
                            title: 'Precio',
                            dataIndex: 'total',
                            key: 'total',
                            render: (text, record) => {
                                return (
                                    <Typography.Text style={{ fontSize: '12px' }}>
                                        <span style={{ fontSize: '12px', color: '#cecece' }}>
                                            ({record?.quantity}x)
                                        </span>{' '}
                                        {formatter.format(text)} {(currency ?? 'MXN').toUpperCase()}
                                    </Typography.Text>
                                )
                            },
                        },
                    ]}
                />
                {!receipt?.hideTaxes && (
                    <>
                        <Row
                            justify="space-between"
                            style={{ marginTop: '10px', marginLeft: '5px', marginRight: '5px' }}
                        >
                            <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>Subtotal</Typography.Text>
                            <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                                {itemsAmounts.subtotalString}
                            </Typography.Text>
                        </Row>
                        <Row
                            justify="space-between"
                            style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px' }}
                        >
                            <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>IVA</Typography.Text>
                            <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                                {itemsAmounts.taxesString}
                            </Typography.Text>
                        </Row>
                        {itemsAmounts?.retentions > 0 && (
                            <Row
                                justify="space-between"
                                style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px' }}
                            >
                                <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                    Retenciones
                                </Typography.Text>
                                <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                                    {itemsAmounts.retentionsString}
                                </Typography.Text>
                            </Row>
                        )}
                    </>
                )}
                {(itemsAmounts?.masterDiscount > 0 || (paidIn === 'bank' && itemsAmounts.feeAdded > 0)) && (
                    <Row justify="space-between" style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px' }}>
                        <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>Descuento 🎁</Typography.Text>
                        <Typography.Text
                            style={{
                                fontSize: '12px',
                                fontWeight: 'normal',
                                fontStyle: 'italic',
                            }}
                            type="secondary"
                        >
                            -{' '}
                            {paidIn === 'bank'
                                ? formatter.format(itemsAmounts.masterDiscount + itemsAmounts.feeAdded)
                                : itemsAmounts.masterDiscountString}
                        </Typography.Text>
                    </Row>
                )}
                <Row justify="space-between" style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px' }}>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>Total</Typography.Text>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                        {' '}
                        {paidIn === 'bank' && itemsAmounts.feeAdded > 0
                            ? itemsAmounts.totalWithoutFeeString
                            : itemsAmounts.totalString}{' '}
                        {(currency ?? 'MXN').toUpperCase()}
                    </Typography.Text>
                </Row>
                <Row justify="center">
                    <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
                        Válido para autofactura hasta el {moment(validUntil).format('DD/MM/YYYY HH:mm')}
                    </Typography.Text>
                </Row>
                <PoweByGig hidePBG={receipt.user.brand?.hidePBG} />
            </div>
        )
    }

    const ReceiptDownload = () => {
        return (
            <div
                className="d-flex flex-column"
                style={{
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '10px',
                    marginTop: '25px',
                }}
            >
                <div
                    className="d-flex flex-row flex-center"
                    style={{
                        width: '100%',
                        padding: '15px',
                        borderRadius: '10px',
                        border: `1px dotted ${primaryColor}`,
                        cursor: loading ? 'wait' : 'pointer',
                    }}
                    onClick={async () => {
                        if (downloading) return
                        setDownloading(true)
                        const url = pdf
                        const a = document.createElement('a')
                        a.target = '_blank'
                        a.href = url
                        a.download = `${id}.pdf`
                        a.click()
                        a.remove()
                        setTimeout(() => {
                            setDownloading(false)
                        }, 2000)
                    }}
                >
                    <Row align="middle">
                        {loading ? (
                            <VscLoading
                                style={{
                                    animation: 'spin 1s infinite linear',
                                    marginRight: '14px',
                                    color: primaryColor,
                                }}
                            />
                        ) : (
                            <AiOutlineCloudDownload style={{ marginRight: '14px', color: primaryColor }} />
                        )}
                        <Typography.Text style={{ color: primaryColor }}>Descargar Recibo</Typography.Text>
                    </Row>
                </div>
            </div>
        )
    }

    const Header = () => {
        return (
            <Row justify="space-between" align="middle">
                <LogoHeader supportEmail={supportEmail} alias={alias} logo={logo} livemode={livemode} />
                {/* <LegalData /> */}
            </Row>
        )
    }

    const InvoiceFormSection = () => {
        return (
            <div
                style={{
                    backgroundColor: 'white',
                    height: 'auto',
                    width: '100%',
                    padding: '30px 25px',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.04)',
                    // borderTop: !livemode ? `3px solid ${primaryColor}` : ''
                }}
            >
                {/* {window.innerWidth > 780 ? <Header /> : <Typography.Text>Ingresa tu información</Typography.Text>} */}
                {/* <ReceiptData /> */}
                <div style={{ marginTop: '0px' }}></div>
                {!state ? (
                    <InvoiceForm receipt={receipt} onSuccess={(b) => setState(b)} />
                ) : loading ? (
                    <DownloadingInvoice />
                ) : (
                    <InvoicesSuccessCard />
                )}
            </div>
        )
    }

    return (
        <>
            {modalDemo && <ModalDemo open={modalDemo} close={() => setModalDemo(false)} />}
            <Row justify="center">
                <Col xs={24}>
                    <div style={{ padding: '20px', width: '100%' }}>
                        <Row
                            style={{ width: '100%' }}
                            gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32,
                            }}
                            justify="center"
                        >
                            <Col xs={23} lg={0}>
                                <ReceiptAmount />

                                <div style={{ marginBottom: '25px' }}></div>
                            </Col>
                            <Col xs={23} lg={18} xl={12} xxl={8}>
                                <InvoiceFormSection />
                            </Col>
                            <Col xs={0} lg={6} xl={7} xxl={6}>
                                <ReceiptAmount />
                                {/* <ReceiptData /> */}
                                <ReceiptDownload />
                                <TryGigstackCard hidePBG={receipt.user.brand?.hidePBG} />
                            </Col>

                            <Col xs={23} lg={0}>
                                <ReceiptDownload />
                            </Col>
                            <Col xs={23} lg={0}>
                                <TryGigstackCard hidePBG={receipt.user.brand?.hidePBG} />
                            </Col>
                        </Row>
                    </div>
                    <PrivacyBanner />
                </Col>
            </Row>
        </>
    )
}

export default GenerateInvoice
