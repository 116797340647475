import { Row, Typography, Space } from 'antd'
import {
    NumberCircleOne,
    NumberCircleTwo,
    NumberCircleThree,
} from '@phosphor-icons/react'
import { memo } from 'react'

export const OxxoPaymentInfo = memo(({ paymentData }) => {
    return (
        <Space
            direction="vertical"
            size="middle"
            style={{ width: '100%', textAlign: 'left', marginTop: '15px', marginBottom: '15px' }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <NumberCircleOne size={24} style={{ marginRight: '8px' }} />
                <Typography.Text className="p-base-regular neutral-1">
                    Añade tus datos y haz click en <span className="p-base-bold"> &quot;Generar Recibo&quot;</span>
                </Typography.Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <NumberCircleTwo size={24} style={{ marginRight: '8px', minWidth: '24px' }} />
                <Typography.Text className="p-base-regular neutral-1">
                    Generarás una línea de captura, la cual <span className="p-base-bold">tendrás hasta 72 hrs</span>{' '}
                    para realizar el pago en tiendas OXXO.
                </Typography.Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <NumberCircleThree size={24} style={{ marginRight: '8px' }} />
                <Typography.Text className="p-base-regular neutral-1">
                    Tu pago se reflejará en tiempo real
                </Typography.Text>
            </div>
        </Space>
    )
})

OxxoPaymentInfo.displayName = 'OxxoPaymentInfo'

const OxxoSection = memo((props) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <OxxoPaymentInfo paymentData={props.paymentData} />
            <Row></Row>
        </div>
    )
})

OxxoSection.displayName = 'OxxoSection'

export default OxxoSection
