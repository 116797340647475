import { createSlice } from '@reduxjs/toolkit'

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState: {
        paymentInReview: false,
        waittingForOxxo: false,
        paymentCompletedContext: false,
    },
    reducers: {
        setPaymentInReview: (state, action) => {
            state.paymentInReview = action.payload
        },
        setWaittingForOxxo: (state, action) => {
            state.waittingForOxxo = action.payload
        },
        setPaymentCompleted: (state, action) => {
            state.paymentCompletedContext = action.payload
        },
    },
})

export const { setPaymentInReview, setWaittingForOxxo, setPaymentCompleted } = paymentsSlice.actions

export default paymentsSlice.reducer
